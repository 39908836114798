.app {
    font-family: Arial, sans-serif;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    /* background-color: #f0f0f0; */
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  /* .logout-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
  } */
  
  .content {
    display: flex;
    padding: 1rem;
  }
  
  .key-apps, .service-status {
    flex: 1;
    padding: 1rem;
  }
  
  .enter-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 0.5rem;
    text-align: left;
  }
  
  .action-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
  }
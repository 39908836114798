.landing-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* background-color: #f5f5f5; */
  }
  
  .landing-content {
    text-align: center;
  }
  
  .logo {
    margin-bottom: 20px;
  }
  
  .logo-bars {
    color: #007bff;
    font-size: 24px;
    font-weight: bold;
    margin-right: 5px;
  }
  
  .logo-text {
    font-size: 24px;
    font-weight: bold;
  }
  
  .welcome-text {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .ntp-text {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .login-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }